//
// Passsword
//

[data-toggle-password] {
    &::before {
        content: "";
        width: $password-icon-bg-width;
        height: $password-icon-bg-height;
        background-color: currentColor;
        mask-image: $password-icon-bg-image-on;
        mask-size: 100%;
    }

    &.pw-hidden {
        &::before {
            mask-image: $password-icon-bg-image-off;
        }
    }
}