// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #FFF !default;
$gray-100: #F6F7F8 !default;
$gray-200: #F4F4F4 !default;
$gray-300: #EDEEF0 !default;
$gray-400: #D6DAE2 !default;
$gray-500: #A5ABB3 !default;
$gray-600: #989EA6 !default;
$gray-700: #707070 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$red:               #D82C5B !default;
$yellow:            #EBCF4F !default;
$green:             #31CF80 !default;
$cyan:              #2DADCF !default;
$light-green:       #F1F5F7 !default;
$blue:              #0075C7 !default;
// scss-docs-end color-variables

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   1.5 !default;

// scss-docs-start theme-color-variables
$primary:           #00BAC7 !default;
$secondary:         #9EA9B4 !default;
$success:           $green !default;
$info:              $cyan !default;
$warning:           $yellow !default;
$danger:            $red !default;
$light:             $gray-100 !default;
$dark:              #0E2238 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary":      $primary,
    "secondary":    $secondary,
    "success":      $success,
    "info":         $info,
    "warning":      $warning,
    "danger":       $danger,
    "light":        $light,
    "dark":         $dark,
    "light-green":  $light-green,
    "white":        $white,
    "black":        $black,
    "blue":         $blue,
    "gray-100":     $gray-100,
    "gray-200":     $gray-200,
    "gray-300":     $gray-300,
    "gray-400":     $gray-400,
    "gray-500":     $gray-500,
    "gray-600":     $gray-600,
    "gray-700":     $gray-700,
    "gray-800":     $gray-800,
    "gray-900":     $gray-900
) !default;
// scss-docs-end theme-colors-map

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows:                            true !default;
$enable-negative-margins:                   true !default;

// Prefix for :root CSS variables

$variable-prefix:                           bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                                    $variable-prefix !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.875rem !default;  // 30px
$spacers: (
    0: 0,
    1: $spacer * .125,       // 3.75px
    2: $spacer * .25,        // 7.5px
    3: $spacer * .333333,    // 10px
    4: $spacer * .5,         // 15px
    5: $spacer * .6666666667,// 20px
    6: $spacer,              // 30px
    7: $spacer * 1.5,        // 45px
    8: $spacer * 2,          // 60px
    9: $spacer * 3,          // 90px
    10: $spacer * 4.5,       // 135px
) !default;
// scss-docs-end spacer-variables-maps

// Custom hight and width sizes
$custom-sizes: (
    unset: unset,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  	1px: 1px,
  	2px: 2px,
  	3px: 3px,
  	4px: 4px,
  	5px: 5px,
    6px: 6px,
    7px: 7px,
    8px: 8px,
    9px: 9px,
  	10px: 10px,
  	15px: 15px,
  	20px: 20px,
  	25px: 25px,
  	30px: 30px,
  	35px: 35px,
  	40px: 40px,
  	45px: 45px,
  	50px: 50px,
  	55px: 55px,
  	60px: 60px,
  	65px: 65px,
  	70px: 70px,
  	75px: 75px,
  	80px: 80px,
  	85px: 85px,
  	90px: 90px,
  	95px: 95px,
  	100px: 100px,
  	125px: 125px,
  	150px: 150px,
	175px: 175px,
  	200px: 200px,
    225px: 225px,
  	250px: 250px,
    275px: 275px,
  	300px: 300px,
    325px: 325px,
  	350px: 350px,
    375px: 375px,
  	400px: 400px,
    425px: 425px,
  	450px: 450px,
    475px: 475px,
  	500px: 500px,
  	550px: 550px,
  	600px: 600px,
  	650px: 650px,
  	700px: 700px,
  	750px: 750px,
  	800px: 800px,
  	850px: 850px,
  	900px: 900px,
  	950px: 950px,
  	1000px: 1000px
) !default;

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  	1px: 1px,
  	2px: 2px,
  	3px: 3px,
  	4px: 4px,
  	5px: 5px,
    6px: 6px,
    7px: 7px,
    8px: 8px,
    9px: 9px,
  	10px: 10px,
  	15px: 15px,
  	20px: 20px,
  	25px: 25px,
  	30px: 30px,
  	35px: 35px,
  	40px: 40px,
  	45px: 45px,
  	50px: 50px,
  	55px: 55px,
  	60px: 60px,
  	65px: 65px,
  	70px: 70px,
  	75px: 75px,
  	80px: 80px,
  	85px: 85px,
  	90px: 90px,
  	95px: 95px,
  	100px: 100px,
  	125px: 125px,
  	150px: 150px,
	175px: 175px,
  	200px: 200px,
    225px: 225px,
  	250px: 250px,
    275px: 275px,
  	300px: 300px,
    325px: 325px,
  	350px: 350px,
    375px: 375px,
  	400px: 400px,
    425px: 425px,
  	450px: 450px,
    475px: 475px,
  	500px: 500px,
  	550px: 550px,
  	600px: 600px,
  	650px: 650px,
  	700px: 700px,
  	750px: 750px,
  	800px: 800px,
  	850px: 850px,
  	900px: 900px,
  	950px: 950px,
  	1000px: 1000px
) !default;
// scss-docs-end position-map

// scss-docs-start filters-variables-maps
$filters: (
    blur-1px: blur(1px),
    blur-2px: blur(2px),
    blur-3px: blur(3px),
    blur-4px: blur(4px),
    blur-5px: blur(5px),
    grayscale-0: grayscale(0),
    grayscale-25: grayscale(25%),
    grayscale-50: grayscale(50%),
    grayscale-75: grayscale(75%),
    grayscale-100: grayscale(100%)
) !default;
// scss-docs-end filters-variables-maps

// Body
//
// Settings for the `<body>` element.

$body-bg:                                   #F8FAFB !default;
$body-color:                                $dark !default;

// Links
//
// Style anchor elements.

$link-decoration:                           none !default;
$link-hover-decoration:                     none !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:                   $spacer !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1320px,
  xxl: 1480px
) !default;
// scss-docs-end container-max-widths

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:                         $spacer !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width:                              1px !default;
$border-color:                              $gray-200 !default;
$border-color-translucent:                  rgba($gray-600, .1) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:                             .625rem !default;// 10px
$border-radius-sm:                          .25rem !default; // 5px
$border-radius-lg:                          .75rem !default; // 12px
$border-radius-xl:                          1rem !default;   // 16px
$border-radius-2xl:                         2rem !default;   // 32px
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow:                                0 .1875rem .1875rem 0 rgba($dark, .07) !default;
$box-shadow-sm:                             0 .125rem .125rem 0 rgba($dark, .07) !default;
$box-shadow-lg:                             0 1rem 3rem rgba($dark, .1) !default;
$box-shadow-inset:                          inset 0 1px 2px rgba($dark, .07) !default;
// scss-docs-end box-shadow-variables

$component-active-color:                    $white !default;
$component-active-bg:                       $primary !default;

// scss-docs-start caret-variables
$caret-width:                               .75em !default;
$caret-vertical-align:                      $caret-width * .85 !default;
$caret-spacing:                             $caret-width * 1.75 !default;

// Dashly variabble
$caret-bg-image:                            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>arrow-down-1</title><path d='M12,19.5a2.3,2.3,0,0,1-1.729-.78L.46,7.568A1.847,1.847,0,0,1,3.233,5.129l8.579,9.752a.25.25,0,0,0,.376,0l8.579-9.752A1.847,1.847,0,1,1,23.54,7.568L13.732,18.716A2.31,2.31,0,0,1,12,19.5Z'/></svg>") !default;
// scss-docs-end caret-variables

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:                    'Open Sans', sans-serif !default;
// stylelint-enable value-keyword-case
$font-family-base:                          var(--#{$prefix}font-sans-serif) !default;
$font-family-input:                         'Open Sans', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Segoe UI', 'Apple Color Emoji', 'Twemoji Mozilla', 'Noto Color Emoji', 'EmojiOne Color', 'Android Emoji', sans-serif, system-ui, -apple-system !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base:                            .875rem !default; // 14px
$font-size-sm:                              $font-size-base * .7857142857 !default; // 11px
$font-size-lg:                              $font-size-base * 1.1428571429 !default; // 16px

$font-weight-lighter:                       300 !default;
$font-weight-light:                         300 !default;
$font-weight-normal:                        400 !default;
$font-weight-semibold:                      600 !default;
$font-weight-bold:                          700 !default;
$font-weight-bolder:                        700 !default;

$h1-font-size:                              1.5rem !default; // 24px
$h2-font-size:                              1.375rem !default; // 22px
$h3-font-size:                              1.125rem !default; // 18px
$h4-font-size:                              $font-size-base !default; // 14px
$h5-font-size:                              .75rem !default; // 12px
$h6-font-size:                              .625rem !default; // 10px
// scss-docs-end font-variables

// scss-docs-start headings-variables
$headings-margin-bottom:                    $spacer * .785 !default;
$headings-font-weight:                      $font-weight-bold !default;
$headings-line-height:                      1.375 !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 3rem,   // 48px
  2: 2.25rem,// 36px
  3: 2rem,   // 32px
  4: 1.75rem,// 28px
  5: 1.5rem, // 24px
  6: 1.25rem // 20px
) !default;

$display-font-weight:                       $font-weight-bold !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
$lead-font-weight:                          $font-weight-semibold !default;
$small-font-size:                           1em !default;

$text-muted:                                rgba(var(--#{$prefix}body-color-rgb), .3525) !default;

$hr-color:                                  $gray-600 !default;

$hr-opacity:                                .1 !default;

$dt-font-weight:                            $font-weight-normal !default;
$dt-color:                                  $secondary !default;
// scss-docs-end type-variables


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y:                      1rem !default;
$table-cell-padding-x:                      1.25rem !default;
$table-cell-padding-y-sm:                   calc($table-cell-padding-y / 2) !default;
$table-cell-padding-x-sm:                   $table-cell-padding-x !default;

$table-th-font-weight:                      $font-weight-bold !default;

$table-active-bg-factor:                    .1 !default;
$table-active-bg:                           rgba($primary, $table-active-bg-factor) !default;

$table-hover-bg-factor:                     .1 !default;
$table-hover-bg:                            rgba($primary, $table-hover-bg-factor) !default;

$table-border-color:                        var(--#{$prefix}border-color) !default;

// Dashly variables
$table-th-color:                            $text-muted !default;
$table-th-font-size:                        .75rem !default;
$table-th-cell-padding-y:                   calc($table-cell-padding-y / 2) !default;
$table-th-cell-padding-x:                   1.25rem !default;
$table-th-cell-padding-y-sm:                calc($table-cell-padding-y / 2) !default;
$table-th-cell-padding-x-sm:                $table-cell-padding-x !default;

$table-head-light-bg-opacity:               .1 !default;
$table-head-light-bg:                       rgba(var(--#{$prefix}gray-600-rgb), $table-head-light-bg-opacity) !default;
// scss-docs-end table-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:                       .53125rem!default;
$input-btn-font-size:                       $font-size-base !default;

$input-btn-focus-width:                     .25rem !default;
$input-btn-focus-color-opacity:             .25 !default;
$input-btn-focus-color:                     rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:                      0.75rem !default;
$input-btn-focus-box-shadow:                0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:                    .375rem !default;
$input-btn-font-size-sm:                    $font-size-sm !default;

$input-btn-padding-y-lg:                    .6875rem !default;
$input-btn-font-size-lg:                    $font-size-lg !default;
// scss-docs-end input-btn-variables


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:                             $input-btn-padding-y !default;
$btn-padding-x:                             1.5625rem !default;

$btn-padding-y-sm:                          $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                          1.25rem !default;

$btn-padding-y-lg:                          $input-btn-padding-y-lg !default;
$btn-padding-x-lg:                          $spacer !default;

$btn-font-weight:                           $font-weight-semibold !default;
$btn-box-shadow:                            null, 0 0 transparent !default;
$btn-focus-width:                           12px !default;
$btn-focus-color-opacity:                   .35 !default;
$btn-focus-color:                           rgba(var(--#{$prefix}btn-focus-shadow-rgb), $btn-focus-color-opacity) !default;
$btn-focus-blur:                            4px !default;
$btn-focus-box-shadow:                      0 $btn-focus-blur $btn-focus-width $btn-focus-color !default;
$btn-active-box-shadow:                     0 0 transparent !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                         1.25rem !default;
$btn-border-radius-lg:                      1.5rem !default;
$btn-border-radius-sm:                      $btn-border-radius !default;
// scss-docs-end btn-variables


// Forms

// scss-docs-start form-text-variables
$form-text-font-size:                       .85715em !default;

// scss-docs-start form-label-variables
$form-label-margin-bottom:                  .3125rem !default;
$form-label-font-size:                      .75rem !default;
$form-label-font-weight:                    $font-weight-bold !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y:                           .625rem !default;
$input-font-family:                         $font-family-input !default;
$input-font-size:                           .75rem !default;

$input-bg:                                  var(--#{$prefix}form-control-bg) !default;
$input-disabled-bg:                         $gray-300 !default;

$input-color:                               var(--#{$prefix}body-color) !default;
$input-border-color:                        var(--#{$prefix}gray-300) !default;
$input-box-shadow:                          0 0 transparent !default;

$input-border-radius:                       $border-radius !default;
$input-border-radius-lg:                    $input-border-radius !default;
$input-border-radius-sm:                    $input-border-radius !default;

$input-focus-border-color:                  $primary !default;
$input-focus-width:                         0 !default;
$input-focus-color-opacity:                 .25 !default;
$input-focus-blur:                          .75rem !default;
$input-focus-box-shadow:                    0 0 $input-focus-blur $input-focus-width rgba($component-active-bg, $input-focus-color-opacity) !default;

$input-placeholder-color:                   rgba(var(--#{$prefix}body-color-rgb), .4) !default;

// Dashly variable
$input-single-text-font-size:               2rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width:                    1rem !default;
$form-check-padding-start:                  $form-check-input-width + .5rem !default;
$form-check-margin-bottom:                  1.125rem !default;

$form-check-input-active-filter:            brightness(100%) !default;

$form-check-input-bg:                       $input-bg !default;
$form-check-input-border:                   1px solid $input-border-color !default;
$form-check-input-border-radius:            .1875rem !default;
$form-check-input-focus-border:             $input-border-color !default;
$form-check-input-focus-box-shadow:         $input-btn-focus-box-shadow !default;

$form-check-input-checked-color:            $component-active-color !default;
$form-check-input-checked-bg-image:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$form-check-input-checked-color}' class='bi bi-check-lg' viewBox='0 0 16 16'><path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/></svg>") !default;

// Dashly variables
$form-check-label-size:                     $form-label-font-size !default;
$form-check-label-font-weight:              $font-weight-semibold !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-color:                         $form-check-input-bg !default;
$form-switch-width:                         2.75em !default;
$form-switch-height:                        1.5em !default;
$form-switch-border-color:                  $input-border-color !default;
$form-switch-bg-image:                      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>") !default;

$form-switch-focus-color:                   $form-switch-color !default;
$form-switch-focus-bg-image:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$gray-400}'/></svg>") !default;

$form-switch-checked-color:                 $component-active-color !default;
$form-switch-checked-bg:                    $component-active-bg !default;
$form-switch-checked-bg-image:              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-bg:                      $input-bg !default;
$input-group-addon-border-color:            $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-indicator-color:               $gray-800 !default;
$form-select-indicator:                     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

$form-select-box-shadow:                    0 0 transparent !default;
$form-select-focus-box-shadow:              $form-select-box-shadow !default;
// scss-docs-end form-select-variables

// scss-docs-start form-file-variables
$form-file-button-bg:                       $gray-300 !default;
$form-file-button-hover-bg:                 shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables


// Form validation

// scss-docs-start form-feedback-variables
$form-feedback-font-size:                   $form-label-font-size !default;
// scss-docs-end form-feedback-variables


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-main-backdrop:                      1090 !default;
$zindex-sidebar:                            1000 !default;
// scss-docs-end zindex-stack


// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                        .90625rem !default;
$nav-link-padding-x:                        1rem !default;

$nav-tabs-border-color:                     rgba($gray-600, .2) !default;
$nav-tabs-border-width:                     $border-width !default;
$nav-tabs-border-radius:                    0 !default;

$nav-tabs-item-margin-y:                    0 !default;
$nav-tabs-item-margin-x:                    .75rem !default;
$nav-tabs-link-border-width:                2px !default;

$nav-tabs-link-hover-border-color:          transparent !default;
$nav-tabs-link-active-color:                var(--#{$prefix}primary) !default;
$nav-tabs-link-active-bg:                   transparent !default;
$nav-tabs-link-active-border-color:         transparent transparent $primary !default;

// Dashly variables
$nav-tabs-link-padding-y:                   .625rem !default;
$nav-tabs-link-padding-x:                   0 !default;
$nav-tabs-link-color:                       var(--#{$prefix}body-color) !default;
$nav-tabs-link-font-weight:                 $font-weight-semibold !default;


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                          $spacer !default;
$navbar-padding-x:                          $spacer !default;

$navbar-nav-link-padding-x:                 $navbar-padding-x !default;

$navbar-brand-padding-y:                    .375rem !default;
$navbar-brand-margin-end:                   0 !default;

// Dashly variables
$navbar-brand-margin-bottom:                2.375rem !default;

$navbar-toggler-padding-y:                  0 !default;
$navbar-toggler-padding-x:                  0 !default;
$navbar-toggler-border-radius:              0 !default;
$navbar-toggler-focus-width:                0 !default;
$navbar-toggler-transition:                 none !default;

// Dashly variabbles
$navbar-link-icon-width:                    1.125rem !default;
$navbar-link-icon-margin-right:             .625rem !default;

$navbar-vertical-width:                     16.25rem !default;
$navbar-vertical-padding-x:                 $navbar-padding-x !default;
$navbar-vertical-active-border-radius:      $spacer !default;
$navbar-vertical-condensed-width:           4.125rem !default;
$navbar-vertical-condensed-legend-width:    0.25rem !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                         $secondary !default;
$navbar-dark-hover-color:                   $primary !default;
$navbar-dark-active-color:                  $primary !default;
$navbar-dark-toggler-icon-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>navigation-menu</title><rect fill='#{$navbar-dark-color}' x='0.5' y='2.5' width='23' height='3' rx='1' ry='1'/><rect fill='#{$navbar-dark-color}' x='0.5' y='10.5' width='23' height='3' rx='1' ry='1'/><rect fill='#{$navbar-dark-color}' x='0.5' y='18.5' width='23' height='3' rx='1' ry='1'/></svg>") !default;
$navbar-dark-toggler-border-color:          transparent !default;

$navbar-light-color:                        $secondary !default;
$navbar-light-hover-color:                  $primary !default;
$navbar-light-active-color:                 $primary !default;
$navbar-light-toggler-icon-bg:              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>navigation-menu</title><rect fill='#{$navbar-light-color}' x='0.5' y='2.5' width='23' height='3' rx='1' ry='1'/><rect fill='#{$navbar-light-color}' x='0.5' y='10.5' width='23' height='3' rx='1' ry='1'/><rect fill='#{$navbar-light-color}' x='0.5' y='18.5' width='23' height='3' rx='1' ry='1'/></svg>") !default;
$navbar-light-toggler-border-color:         transparent !default;

// Dashly variabbles
$navbar-dark-active-bg:                     rgba($primary, .12) !default;
$navbar-dark-active-color-sub:              $white !default;
$navbar-dark-collapse-icon:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-dark-collapse-icon-hover:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-dark-hover-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-dark-collapse-icon-active:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-dark-active-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-dark-vertical-border-color:         shade-color($body-bg, 87.5%) !default;

$navbar-light-active-bg:                    rgba($primary, .06) !default;
$navbar-light-active-color-sub:             var(--#{$prefix}body-color) !default;
$navbar-light-collapse-icon:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-light-collapse-icon-hover:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-light-hover-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-light-collapse-icon-active:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' viewBox='0 0 12 12' width='12' height='12'><g transform='matrix(0.5,0,0,0.5,0,0)'><path d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='#{$navbar-light-active-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'></path></g></svg>") !default;
$navbar-light-vertical-border-color:        rgba($gray-500, .1) !default;

$navbar-popover-box-shadow:                 1rem 1rem 3rem rgba($dark, .1) !default;


// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                        10rem !default;
$dropdown-padding-x:                        0 !default;
$dropdown-padding-y:                        .375rem !default;
$dropdown-spacer:                           .625rem !default;
$dropdown-color:                            var(--#{$prefix}body-color) !default;
$dropdown-bg:                               $white !default;
$dropdown-border-color:                     var(--#{$prefix}border-color) !default;
$dropdown-border-radius:                    $border-radius !default;
$dropdown-border-width:                     0 !default;
$dropdown-inner-border-radius:              subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:                       $gray-300 !default;
$dropdown-divider-margin-y:                 $spacer * .25 !default;
$dropdown-box-shadow:                       $box-shadow-lg !default;

$dropdown-link-color:                       var(--#{$prefix}body-color) !default;
$dropdown-link-hover-color:                 shade-color($body-color, 10%) !default;
$dropdown-link-hover-bg:                    var(--#{$prefix}gray-100) !default;

$dropdown-link-active-color:                $dropdown-link-hover-color !default;
$dropdown-link-active-bg:                   $dropdown-link-hover-bg !default;

$dropdown-item-padding-y:                   $spacer * .25 !default;
$dropdown-item-padding-x:                   $spacer * .5 !default;

$dropdown-header-color:                     var(--#{$prefix}secondary) !default;

// Dashly variables
$dropdown-header-font-weight:               $font-weight-bold;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:                       $light !default;
$dropdown-dark-bg:                          shade-color($white, 80.5%) !default;
$dropdown-dark-border-color:                $dropdown-border-color !default;
$dropdown-dark-divider-bg:                  rgba($gray-300, .1) !default;
$dropdown-dark-box-shadow:                  $dropdown-box-shadow !default;
$dropdown-dark-link-color:                  $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:            $white !default;
$dropdown-dark-link-hover-bg:               shade-color($gray-100, 75%) !default;
$dropdown-dark-link-active-color:           $dropdown-dark-link-hover-color !default;
$dropdown-dark-link-active-bg:              $dropdown-dark-link-hover-bg !default;
// scss-docs-end dropdown-dark-variables


// Pagination

// scss-docs-start pagination-variables
$pagination-color:                          var(--#{$prefix}link-color) !default;
$pagination-bg:                             var(--#{$prefix}body-bg) !default;
$pagination-border-color:                   var(--#{$prefix}border-color-translucent) !default;

$pagination-hover-color:                    var(--#{$prefix}link-hover-color) !default;
$pagination-hover-bg:                       var(--#{$prefix}gray-100) !default;
$pagination-hover-border-color:             $pagination-border-color !default; 

$pagination-active-color:                   $component-active-color !default;
$pagination-active-bg:                      $component-active-bg !default;
$pagination-active-border-color:            $pagination-active-bg !default;
// scss-docs-end pagination-variables


// Cards

// scss-docs-start card-variables
$card-spacer-y:                             $spacer * .6666666667 !default;
$card-spacer-x:                             $spacer * .6666666667 !default;
$card-border-width:                         1px !default;
$card-border-color:                         var(--#{$prefix}border-color-translucent) !default;
$card-cap-padding-y:                        $card-spacer-y !default;
$card-cap-padding-x:                        $card-spacer-x !default;
$card-cap-bg:                               transparent !default;
$card-bg:                                   $white !default;

// Dashly variable
$card-margin-bottom:                        $spacer !default;
$card-box-shadow:                           $box-shadow !default;
// scss-docs-end card-variables


// Tooltips

// scss-docs-start tooltip-variables
$tooltip-color:                             var(--#{$prefix}white) !default;
$tooltip-bg:                                var(--#{$prefix}dark) !default;
$tooltip-opacity:                           1 !default;
$tooltip-border-radius:                     $border-radius-sm !default;

// Dashly variable
$tooltip-box-shadow:                        $box-shadow !default;
// scss-docs-end tooltip-variables


// Popovers

// scss-docs-start popover-variables    
$popover-bg:                                var(--#{$prefix}white) !default;
$popover-border-width:                      $border-width !default;
$popover-border-color:                      var(--#{$prefix}border-color-translucent) !default;
$popover-border-radius:                     $border-radius !default;
$popover-inner-border-radius:               subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                        $box-shadow-sm !default;

$popover-header-bg:                         var(--#{$prefix}light) !default;
// scss-docs-end popover-variables


// Badges

// scss-docs-start badge-variables
$badge-font-size:                           72% !default;
$badge-font-weight:                         $font-weight-semibold !default;
$badge-padding-y:                           .25em !default;
$badge-padding-x:                           .75em !default;
$badge-border-radius:                       $border-radius-sm !default;

// Dashly variable
$badge-circle-size:                         1.75em !default;
// scss-docs-end badge-variables


// Toasts

// scss-docs-start toast-variables
$toast-padding-x:                           calc($spacer / 2) !default;
$toast-padding-y:                           calc($spacer / 2.5) !default;
$toast-font-size:                           $font-size-base !default;
$toast-background-color:                    rgba($white, .9) !default;
// scss-docs-end toast-variables


// Modals

// scss-docs-start modal-variables
$modal-content-bg:                          var(--#{$prefix}white) !default;
$modal-content-border-width:                0 !default;

$modal-backdrop-bg:                         $dark !default;
$modal-backdrop-opacity:                    .25 !default;
// scss-docs-end modal-variables


// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
$alert-padding-y:                           calc($spacer / 1.5) !default;
$alert-padding-x:                           $spacer !default;
$alert-border-radius:                       $border-radius-sm !default;
$alert-bg-scale:                            0% !default;
$alert-border-scale:                        0% !default;
$alert-color-scale:                         6% !default;
// scss-docs-end alert-variables


// Progress bars

// scss-docs-start progress-variables
$progress-height:                           .75rem !default;
$progress-bg:                               var(--#{$prefix}gray-300) !default;
$progress-border-radius:                    $border-radius !default;
$progress-bar-color:                        $white !default;
$progress-bar-bg:                           $primary !default;


// List group

// scss-docs-start list-group-variables
$list-group-color:                          var(--#{$prefix}body-color) !default;
$list-group-bg:                             transparent !default;
$list-group-border-color:                   rgba($gray-600, .1) !default;
$list-group-border-width:                   $border-width !default;
$list-group-border-radius:                  $border-radius !default;

$list-group-item-padding-y:                 $spacer * .5 !default;
$list-group-item-padding-x:                 1.25rem !default;

$list-group-hover-bg:                       rgba($primary, .1) !default;
$list-group-active-color:                   $list-group-color !default;
$list-group-active-bg:                      $list-group-hover-bg !default;
$list-group-active-border-color:            $list-group-active-bg !default;

$list-group-action-color:                   $list-group-color !default;
$list-group-action-hover-color:             $list-group-action-color !default;

$list-group-action-active-color:            var(--#{$prefix}body-color) !default;
$list-group-action-active-bg:               $list-group-hover-bg !default;
// scss-docs-end list-group-variables


// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:                      .75rem !default;
$breadcrumb-padding-y:                      0 !default;
$breadcrumb-padding-x:                      0 !default;
$breadcrumb-item-padding:                   .5rem !default;
$breadcrumb-margin-bottom:                  0 !default;
$breadcrumb-bg:                             transparent !default;
$breadcrumb-divider-color:                  $secondary !default;
$breadcrumb-divider:                        url("data:image/svg+xml,<svg viewBox='0 0 24 24' height='10' width='10' xmlns='http://www.w3.org/2000/svg'><path d='M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25' fill='none' stroke='#{$breadcrumb-divider-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/></svg>") !default;
$breadcrumb-border-radius:                  0 !default;

// Dashly variabbles
$breadcrumb-active-color:                   var(--#{$prefix}body-color) !default;
$breadcrumb-link-color:                     $secondary !default;
$breadcrumb-font-weight:                    $font-weight-semibold !default;
// scss-docs-end breadcrumb-variables


// Close

// scss-docs-start close-variables
$btn-close-color:                           $body-color !default;
$btn-close-bg:                              url("data:image/svg+xml,<svg height='12' width='12' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>close</title><path fill='#{$btn-close-color}' d='M14.3,12.179a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.442L12.177,9.7a.25.25,0,0,1-.354,0L2.561.442A1.5,1.5,0,0,0,.439,2.563L9.7,11.825a.25.25,0,0,1,0,.354L.439,21.442a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,0,0,2.122-2.121Z'></path></svg>") !default;
$btn-close-focus-shadow:                    0 0 transparent !default;
$btn-close-opacity:                         .75 !default;
$btn-close-hover-opacity:                   1 !default;
$btn-close-focus-opacity:                   1 !default;
// scss-docs-end close-variables


// Code

$code-font-size:                            $small-font-size !default;
$code-color:                                $primary !default;


// Dashly variables

// scss-docs-start opacity-map
$opacity-values: (
    0:   0,
    10:  .1,
    20:  .2,
    30:  .3,
    40:  .4,
    50:  .5,
    60:  .6,
    70:  .7,
    80:  .8,
    90:  .9,
    100: 1
) !default;
// scss-docs-end opacity-map


// Backgrounds

// scss-docs-start background-variables
$bg-soft-scale:                             -87% !default;
// scss-docs-end background-variables


// Main

// scss-docs-start main-variables
$main-container-padding-x:                  .9375rem !default; // Additionaly $grid-gutter-width will be added
$main-content-bg:                           var(--#{$prefix}light-green) !default;
$main-content-position-top:                 13.625rem !default;
// scss-docs-end main-variables


// Avatar

// scss-docs-start avatar-variables
$avatar-size-base:                          3rem !default;
$avatar-size-xxs:                           1.125rem !default;
$avatar-size-xs:                            1.875rem !default;
$avatar-size-sm:                            2.5rem !default;
$avatar-size-lg:                            4rem !default;
$avatar-size-xl:                            5.125rem !default;
$avatar-size-xxl:                           7rem !default;

$avatar-border-radius:                      $border-radius !default;

$avatar-bg:                                 var(--#{$prefix}gray-300) !default;
$avatar-color:                              var(--#{$prefix}gray-500) !default;

$avatar-status-border-color:                var(--#{$prefix}white);                         
// scss-docs-end avatar-variables


// Datepicker

// scss-docs-start datepicker-variables
$datepicker-color:                          var(--#{$prefix}body-color) !default;
$datepicker-bg:                             $white !default;

$datepicker-dark-color:                     $light !default;
$datepicker-dark-bg:                        shade-color($white, 80.5%) !default;
// scss-docs-end datepicker-variables


// Password

// scss-docs-start password-variables
$password-icon-bg-width:                    1rem !default;
$password-icon-bg-height:                   1rem !default;
$password-icon-bg-image-on:                 url("data:image/svg+xml,<svg viewBox='0 0 24 24' height='#{$password-icon-bg-height}' width='#{$password-icon-bg-width}' xmlns='http://www.w3.org/2000/svg'><path d='M12,5.251C7.969,5.183,3.8,8,1.179,10.885a1.663,1.663,0,0,0,0,2.226C3.743,15.935,7.9,18.817,12,18.748c4.1.069,8.258-2.813,10.824-5.637a1.663,1.663,0,0,0,0-2.226C20.2,8,16.031,5.183,12,5.251Z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/><path d='M15.75,12A3.75,3.75,0,1,1,12,8.249,3.749,3.749,0,0,1,15.75,12Z' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/></svg>") !default;
$password-icon-bg-image-off:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='#{$password-icon-bg-height}' width='#{$password-icon-bg-width}'><g><line x1='2.78' y1='21' x2='21.53' y2='3' style='fill: none;stroke: currentColor;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px'/><path d='M9,19.05a9.91,9.91,0,0,0,3,.45c4.1.07,8.26-2.81,10.82-5.64a1.65,1.65,0,0,0,0-2.22,20.06,20.06,0,0,0-3.07-2.76' style='fill: none;stroke: currentColor;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px'/><path d='M17.09,7.27A11.31,11.31,0,0,0,12,6C8,5.93,3.8,8.75,1.18,11.64a1.65,1.65,0,0,0,0,2.22,20,20,0,0,0,4.93,4' style='fill: none;stroke: currentColor;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px'/><path d='M9,15.07a3.85,3.85,0,0,1,5.5-5.28' style='fill: none;stroke: currentColor;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px'/><path d='M15.75,12.75h0A3.75,3.75,0,0,1,12,16.5' style='fill: none;stroke: currentColor;stroke-linecap: round;stroke-linejoin: round;stroke-width: 1.5px'/></g></svg>") !default;
// scss-docs-end password-variables


// Steps

// scss-docs-start steps-variables
$steps-border-radius:                       50rem !default;
$steps-size:                                2.5rem !default;  
$steps-font-size:                           1.125rem !default;
$steps-font-weight:                         $font-weight-bold !default;
$steps-link-bg:                             var(--#{$prefix}gray-300) !default;
$steps-link-color:                          var(--#{$prefix}body-color) !default;
$steps-check-bg-size:                       1rem !default;
$step-check-bg-image:                       url("data:image/svg+xml,<svg height='#{$steps-check-bg-size}' width='#{$steps-check-bg-size}' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><title>check-1</title><path d='M23.374.287a1.5,1.5,0,0,0-2.093.345L7.246,20.2,2.561,15.511A1.5,1.5,0,1,0,.439,17.632l5.935,5.934a1.525,1.525,0,0,0,2.279-.186l15.066-21A1.5,1.5,0,0,0,23.374.287Z'/></svg>") !default;
// scss-docs-end steps-variables


// Charts

// scss-docs-start charts-variables
$chart-height:                              19.375rem !default;
$chart-tooltip-padding-x:                   .5rem !default;
$chart-tooltip-padding-y:                   .375rem !default;
$chart-tooltip-color:                       $white !default;
$chart-tooltip-header-color:                rgba($white, .7) !default;
$chart-tooltip-bg:                          var(--#{$prefix}dark) !default;
$chart-tooltip-font-family:                 $font-family-base !default;
$chart-tooltip-font-size:                   $h6-font-size !default;
$chart-tooltip-border-radius:               $border-radius-sm !default;
// scss-docs-end charts-variables


// Map

// scss-docs-start map-variables
$map-regions-bg:                            var(--#{$prefix}gray-300) !default;
$map-markers-fill:                          var(--#{$prefix}primary) !default;
$map-markers-stroke:                        var(--#{$prefix}white) !default;
$map-markers-hover-fill:                    var(--#{$prefix}primary) !default;
$map-markers-hover-stroke:                  var(--#{$prefix}primary) !default;
$map-zoom-btn-bg:                           var(--#{$prefix}dark) !default;
$map-tooltip-font-family:                   $chart-tooltip-font-family !default;
$map-tooltip-bg:                            var(--#{$prefix}dark) !default;
$map-tooltip-border-radius:                 $chart-tooltip-border-radius !default;
$map-tooltip-font-size:                     $chart-tooltip-font-size !default;
// scss-docs-end map-variables


// Legend circle

// scss-docs-start legend-variables
$legend-circle-diamater:                    .4375rem !default;
$legend-circle-diamater-sm:                 .3125rem !default;
$legend-circle-diamater-lg:                 .5625rem !default;
$legend-circle-bg:                          var(--#{$prefix}secondary) !default;
// scss-docs-end legend-variables


// Dropzone

// scss-docs-start dropzone-variables
$dropzone-bg:                               rgba(var(--#{$prefix}gray-200-rgb), .25) !default;
$dropzone-drag-bg:                          rgba(var(--#{$prefix}gray-200-rgb), .5) !default;
$dropzone-border-width:                     $border-width;
$dropzone-border-color:                     $border-color-translucent;
$dropzone-border-radius:                    $border-radius;
$dropzone-preview-bg:                       var(--#{$prefix}gray-300);
// scss-docs-end dropzone-variables


// List.js

// scss-docs-start listjs-variables
$sort-bg:                                   $gray-500;
$sort-up-bg:                                url("data:image/svg+xml,<?xml version='1.0' encoding='UTF-8'?><svg width='9px' height='4px' viewBox='0 0 9 4' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><title>up</title><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-164.000000, -777.000000)' fill='#{$sort-bg}'><g transform='translate(30.000000, 716.000000)'><g transform='translate(0.000000, 49.000000)'><g transform='translate(134.000000, 12.000000)'><polygon id='up' points='4.5 0 9 4 0 4'></polygon></g></g></g></g></g></svg>") !default;
$sort-down-bg:                              url("data:image/svg+xml,<?xml version='1.0' encoding='UTF-8'?><svg width='9px' height='4px' viewBox='0 0 9 4' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><title>down</title><g  stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-164.000000, -783.000000)' fill='#{$sort-bg}'><g transform='translate(30.000000, 716.000000)'><g transform='translate(0.000000, 49.000000)'><g transform='translate(134.000000, 12.000000)'><polygon id='down' transform='translate(4.500000, 8.000000) scale(1, -1) translate(-4.500000, -8.000000)' points='4.5 6 9 10 0 10'></polygon></g></g></g></g></g></svg>") !default;
// scss-docs-end listjs-variables


// Scrollbar

// scss-docs-start scrollbar-variables
$scrollbar-scroll-bg:                       $gray-600 !default;
// scss-docs-end scrollbar-variables


@import "variables-dark";