/*

Theme: Dashly
Product Page: https://themes.getbootstrap.com/product/dashly-admin-dashboard-template/
Author: Webinning
Author URI: https://webinning.co.uk
Support: support@dashly-theme.com

---

Copyright 2022 Webinning

*/

// Bootstrap functions
@import '~bootstrap/scss/functions';


// Custom variables
@import "user-variables";


// Theme variables
@import "theme/variables";


// Theme configuration
@import "theme/maps";


// Bootstrap configuration
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";


// Theme utilities
@import "theme/utilities";


// Bootstrap utilities
@import "~bootstrap/scss/utilities";


// Theme layout & components 
@import "theme/root";


// Bootstrap layout & components
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";


// Tehme Helpers
@import "theme/helpers";


// Bootstrap Helpers
@import "~bootstrap/scss/helpers";


// Bootstrap utilities API
@import "~bootstrap/scss/utilities/api";


// Theme layout & components
@import 'theme/dashly';


// Custom style
@import 'user';